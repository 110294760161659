import React from "react"
import styles from "./index.module.scss"

type Props = {
  label: string
  onClick: () => void
}

export const Button: React.VFC<Props> = ({ label, onClick }) => {
  return (
    <button className={styles.button} type="button" onClick={onClick}>
      {label}
    </button>
  )
}
