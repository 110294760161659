import React, { useCallback } from "react"
import { StaticImage } from "gatsby-plugin-image"
import scrollTo from "gatsby-plugin-smoothscroll"

// CSS
import Styles from "./index.module.scss"

// Components
import SEO from "@/components/seo"
import { Layout } from "@/components/layout"
import { Form } from "@/components/form"
import { Button } from "@/components/button"
import { Heading } from "@/components/heading"

const PackagePage: React.VFC = () => {
  const handleScroll = useCallback((elementId: string) => {
    scrollTo(elementId)
  }, [])
  return (
    <>
      <SEO title="ECサイトのカート選定から構築・プロモーションまで | TAM" />
      <Layout>
        {/* mv */}
        <div className={Styles.mv}>
          <div className={Styles.mvInner}>
            <h1 className={Styles.mvHeading}>
              <span className={Styles.mvHeadingSub}>
                ECのスモールスタートを応援
              </span>
              ビジネスの成長に合わせた <br aria-hidden="true" />
              <span className={Styles.mvHeadingLarge}>
                ECサイトの運用
                <span className={Styles.mvHeadingCircle}>・</span>構築
              </span>
            </h1>
            <div className={Styles.mvCircleWrap}>
              <p className={Styles.mvCircleText}>
                サイト戦略・
                <br aria-hidden="true" />
                カート選定
              </p>
              <p className={Styles.mvCircleText}>サイト構築</p>
              <p className={Styles.mvCircleText}>
                SEO・広告・
                <br aria-hidden="true" />
                SNS
              </p>
              <p className={Styles.mvCircleText}>
                {" "}
                PDCA運用・
                <br aria-hidden="true" />
                アップデート
              </p>
            </div>
            <p className={Styles.mvDescription}>
              TAMではECのスモールスタートをきりたい企業様を応援します。
              <br aria-hidden="true" />
              ビジネスモデルに合わせて、 まずは
              <span className={Styles.redText}>
                ECサイトの立ち上げを目標に構築
              </span>
              します。
              <br aria-hidden="true" />
              <span className={Styles.redText}>
                必要に応じてECサイトの機能をアップデート
              </span>
              できるので、 ビジネスの成長に合わせたECサイトを利用いただけます。
            </p>
            <div className={Styles.mvImage}>
              <StaticImage
                src="../../../images/service/package/package_1.png"
                alt=""
              />
            </div>
          </div>
        </div>
        {/* gray area */}
        <div className={Styles.grayArea}>
          <div className={Styles.grayAreaInner}>
            <h2 className={Styles.grayAreaHeading}>
              こんなお悩みを解決します。
            </h2>
            <ul className={Styles.grayAreaList}>
              <li className={Styles.grayAreaItem}>
                ECを始めたいけれど、新規事業なので多くの予算はとれない。
              </li>
              <li className={Styles.grayAreaItem}>
                立ち上げ後、軌道に乗ればアップデートをしていきたい。
              </li>
              <li className={Styles.grayAreaItem}>
                実績のある企業に開発してもらいたい。
              </li>
              <li className={Styles.grayAreaItem}>
                既存サイトにトレンドを取り入れたい。
              </li>
            </ul>
          </div>
        </div>
        {/* yellow area */}
        <div className={Styles.yellowArea}>
          <div className={Styles.yellowAreaInner}>
            <Heading
              title="ECサイト構築＋ECサイト運用を行います。"
              subTitle="サービス"
              level="h2"
            />
            <div className={Styles.yellowAreaContent}>
              <div className={Styles.yellowAreaContentHeadingWrap}>
                <h3 className={Styles.yellowAreaContentHeading}>
                  ECサイト構築
                </h3>
                <p className={Styles.text}>
                  お客様からのヒアリング内容をもとに、与件を整理・PGSTを作成、
                  <br aria-hidden="true" className={Styles.pcOnly} />
                  その後、テーマデザイン・サイト構築を行います。
                  <br aria-hidden="true" />
                  まずはECサイトの立ち上げをゴールにECサイト構築を行います。
                </p>
              </div>
              <ul className={Styles.yellowAreaContentList}>
                <li className={Styles.yellowAreaContentItem}>
                  <div className={Styles.yellowAreaContentImageWrap}>
                    <div className={Styles.yellowAreaContentImage}>
                      <StaticImage
                        src="../../../images/service/package/package_2.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className={Styles.yellowAreaContentTextWrap}>
                    <h4 className={Styles.yellowAreaContentItemHeading}>
                      与件の整理
                    </h4>
                    <p className={Styles.text}>
                      企業様がTAMに依頼をいただいた背景や
                      <br aria-hidden="true" className={Styles.pcOnly} />
                      現状の課題、目的を整理します。
                    </p>
                  </div>
                </li>
                <li className={Styles.yellowAreaContentItem}>
                  <div className={Styles.yellowAreaContentImageWrap}>
                    <div className={Styles.yellowAreaContentImage}>
                      <StaticImage
                        src="../../../images/service/package/package_7.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className={Styles.yellowAreaContentTextWrap}>
                    <h4 className={Styles.yellowAreaContentItemHeading}>
                      PGST
                    </h4>
                    <p className={Styles.text}>
                      PGSTとはプロジェクトの目的やゴール、
                      <br aria-hidden="true" className={Styles.pcOnly} />
                      それを達成するためにやるべきことを
                      <br aria-hidden="true" className={Styles.pcOnly} />
                      整理するためのTAM独自のフレームワークです。
                    </p>
                  </div>
                </li>
                <li className={Styles.yellowAreaContentItem}>
                  <div className={Styles.yellowAreaContentImageWrap}>
                    <div className={Styles.yellowAreaContentImage}>
                      <StaticImage
                        src="../../../images/service/package/package_5.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className={Styles.yellowAreaContentTextWrap}>
                    <h4 className={Styles.yellowAreaContentItemHeading}>
                      テーマデザイン
                    </h4>
                    <p className={Styles.text}>
                      カートごとに公開されているデザインテーマ
                      <br aria-hidden="true" className={Styles.pcOnly} />
                      をもとにサイト全体のデザインを調整します。
                    </p>
                  </div>
                </li>
                <li className={Styles.yellowAreaContentItem}>
                  <div className={Styles.yellowAreaContentImageWrap}>
                    <div className={Styles.yellowAreaContentImage}>
                      <StaticImage
                        src="../../../images/service/package/package_3.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className={Styles.yellowAreaContentTextWrap}>
                    <h4 className={Styles.yellowAreaContentItemHeading}>
                      アクセス解析設定
                    </h4>
                    <p className={Styles.text}>
                      Google AnalyticsやSearch Consoleなど
                      <br aria-hidden="true" className={Styles.pcOnly} />
                      アクセス解析が行える状態にセッティング
                    </p>
                  </div>
                </li>
                <li className={Styles.yellowAreaContentItem}>
                  <div className={Styles.yellowAreaContentImageWrap}>
                    <div className={Styles.yellowAreaContentImage}>
                      <StaticImage
                        src="../../../images/service/package/package_4.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className={Styles.yellowAreaContentTextWrap}>
                    <h4 className={Styles.yellowAreaContentItemHeading}>
                      広告設定
                    </h4>
                    <p className={Styles.text}>
                      Google、Facebookなどすぐに広告の運用が
                      <br aria-hidden="true" className={Styles.pcOnly} />
                      できるように設定を行います。
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className={Styles.plusIcon}></div>
            <div className={Styles.yellowAreaContent}>
              <div className={Styles.yellowAreaContentHeadingWrap}>
                <h3 className={Styles.yellowAreaContentHeading}>
                  ECサイト運用
                </h3>
                <p className={Styles.text}>
                  ECサイトの公開後にページ更新や広告出稿、アクセス解析を行います。
                  <br aria-hidden="true" />
                  継続的にPDCA運用を行うことで問題点を洗い出し、必要に応じて機能をアップデートを行います。
                </p>
              </div>
              <ul className={Styles.yellowAreaContentList}>
                <li className={Styles.yellowAreaContentItem}>
                  <div className={Styles.yellowAreaContentImageWrap}>
                    <div className={Styles.yellowAreaContentImage}>
                      <StaticImage
                        src="../../../images/service/package/package_8.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className={Styles.yellowAreaContentTextWrap}>
                    <h4 className={Styles.yellowAreaContentItemHeading}>
                      必要に応じたアップデート
                    </h4>
                    <p className={Styles.text}>
                      必要に応じて機能を追加、アップデートを
                      <br aria-hidden="true" className={Styles.pcOnly} />
                      行います。
                    </p>
                  </div>
                </li>
                <li className={Styles.yellowAreaContentItem}>
                  <div className={Styles.yellowAreaContentImageWrap}>
                    <div className={Styles.yellowAreaContentImage}>
                      <StaticImage
                        src="../../../images/service/package/package_12.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className={Styles.yellowAreaContentTextWrap}>
                    <h4 className={Styles.yellowAreaContentItemHeading}>
                      PDCA運用
                    </h4>
                    <p className={Styles.text}>
                      継続的にPDCAサイクルを回すことで、
                      <br aria-hidden="true" className={Styles.pcOnly} />
                      サイト改善に取り組んでいきます。
                    </p>
                  </div>
                </li>
                <li className={Styles.yellowAreaContentItem}>
                  <div className={Styles.yellowAreaContentImageWrapSmall}>
                    <div className={Styles.yellowAreaContentImageSmall}>
                      <StaticImage
                        src="../../../images/service/package/package_9.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className={Styles.yellowAreaContentTextWrap}>
                    <h4 className={Styles.yellowAreaContentItemHeading}>
                      広告出稿
                    </h4>
                    <p className={Styles.text}>
                      googleリスティングやFacebookなど
                      <br aria-hidden="true" className={Styles.pcOnly} />
                      商材に合わせた広告を提案・出稿します。
                    </p>
                  </div>
                </li>
                <li className={Styles.yellowAreaContentItem}>
                  <div className={Styles.yellowAreaContentImageWrap}>
                    <div className={Styles.yellowAreaContentImage}>
                      <StaticImage
                        src="../../../images/service/package/package_10.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className={Styles.yellowAreaContentTextWrap}>
                    <h4 className={Styles.yellowAreaContentItemHeading}>
                      バナー・ページ更新
                    </h4>
                    <p className={Styles.text}>
                      キャンペーンに合わせてバナーや
                      <br aria-hidden="true" className={Styles.pcOnly} />
                      ページの更新を行います。
                    </p>
                  </div>
                </li>
                <li className={Styles.yellowAreaContentItem}>
                  <div className={Styles.yellowAreaContentImageWrap}>
                    <div className={Styles.yellowAreaContentImage}>
                      <StaticImage
                        src="../../../images/service/package/package_6.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className={Styles.yellowAreaContentTextWrap}>
                    <h4 className={Styles.yellowAreaContentItemHeading}>
                      アクセス解析
                    </h4>
                    <p className={Styles.text}>
                      アクセス解析データや広告データをもとに
                      <br aria-hidden="true" className={Styles.pcOnly} />
                      レポートを作成いたします。
                    </p>
                  </div>
                </li>
                <li className={Styles.yellowAreaContentItem}>
                  <div className={Styles.yellowAreaContentImageWrap}>
                    <div className={Styles.yellowAreaContentImage}>
                      <StaticImage
                        src="../../../images/service/package/package_11.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className={Styles.yellowAreaContentTextWrap}>
                    <h4 className={Styles.yellowAreaContentItemHeading}>
                      プロモーション設計
                    </h4>
                    <p className={Styles.text}>
                      商材にあったマーケティングプランを作成し、
                      <br aria-hidden="true" className={Styles.pcOnly} />
                      最適なプロモーションをご提案します。
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className={Styles.yellowAreaFooter}>
              <h3 className={Styles.yellowAreaFooterHeading}>
                お悩みに合わせて
                <span className={Styles.spNewLine}>柔軟に対応が可能です。</span>
              </h3>
              <p className={Styles.text}>
                TAMではデジタル分野において
                <span className={Styles.spNewLine}>
                  広い範囲での対応が可能です。
                </span>
                <span className={Styles.newLine}>
                  ECについてお悩みの方は下記よりご相談ください。
                </span>
              </p>
              <div className={Styles.buttonWrap}>
                <Button
                  label="お問い合わせ"
                  onClick={() => handleScroll("#contact")}
                />
              </div>
            </div>
          </div>
        </div>
        {/* profile */}
        <div className={Styles.profile}>
          <div className={Styles.profileInner}>
            <Heading
              title="TAM ECチーム"
              subTitle="私たちについて"
              level="h2"
            />
            <div className={Styles.memberLeader}>
              <div className={Styles.memberLeaderImg}>
                <figure>
                  <StaticImage
                    src="../../../images/top/p-person--ohuchi.png"
                    alt=""
                    loading="lazy"
                  />
                </figure>
              </div>
              <div className={Styles.memberLeaderInfo}>
                <p className={Styles.memberLeaderName}>大内 千佳 </p>
                <p className={Styles.memberLeaderJob}>
                  TAM取締役 / ECプロデューサー{" "}
                </p>
                <p className={Styles.memberLeaderText}>
                  ECディレクター10年。ECのWEB担2年。制作側と受託側両面から大規模ECサイトに携わった経験を活かし、クライアントの課題解決・運用効率化を主軸に置いた提案をしています。
                </p>
                <p className={Styles.memberLeaderText}>
                  【Career】
                  <br aria-hidden="true" />
                  ベルメゾンネットなど大手・メーカーECサイトの受託制作に関わった後、転職。大手通販サイト（当時売上1000億円規模）にてプランナーを経験し、自社商品の販売計画・在庫調整なども行う一方、自社サイトの改修ディレクションも担当。その後、ふたたびTAMに戻り、20名でECチームを立ち上げ、大手ECサイトの構築運用に関わる。制作側と運用側両面から大規模ECサイトに携わった経験を活かし、クライアントの運用体制改善などの提案も行っています。
                </p>
                <ul className={Styles.iconList}>
                  <li className={Styles.iconItem}>
                    <a
                      href="https://ja-jp.facebook.com/chika.ouchi"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <StaticImage
                        src="../../../images/top/facebook_icon.svg"
                        alt="Facebookアイコン"
                        loading="lazy"
                      />
                    </a>
                  </li>
                  <li className={Styles.iconItem}>
                    <a
                      href="https://twitter.com/kansai_ec"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <StaticImage
                        src="../../../images/top/twitter_icon.svg"
                        alt="Twitterアイコン"
                        loading="lazy"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <p className={Styles.text}>
              Quality(質)とCapacity(キャパシティ)、Rationale(その根拠)はプロジェクトを滞りなく進行するためにはどれも重要な要素です。
              <br aria-hidden="true" />
              クライアントの皆様に数あるデジタルエージェンシーに中からTAMを選んでいただく理由としてTAMだからこそ提供できるQCRをご紹介します。
            </p>
          </div>
        </div>
        {/* quality */}
        <div className={Styles.intro}>
          <div className={Styles.introInner}>
            <div className={Styles.bgTextWrap}>
              <p className={Styles.bgText}>QUALITY</p>
              <h2 className={Styles.bgHeading}>
                <span className={Styles.bgSubHeading}>QUALITY</span>
                豊富な知識と技術
              </h2>
            </div>
            <p className={Styles.text}>
              TAMのEC開発はECに関する豊富な知識やコミュニティ、フロントエンド技術によって支えられています。
            </p>
            <h3 className={Styles.qualityHeading}>
              Shopify Partner Boot Campに講師として登壇
            </h3>
            <div className={Styles.qualityImage}>
              <StaticImage
                src="../../../images/top/p-job-case--01.jpg"
                alt=""
                loading="lazy"
                quality={80}
              />
            </div>
            <h3 className={Styles.qualityHeading}>
              関西EC交流会を定期的に実施
            </h3>
            <div className={Styles.qualityImage}>
              <StaticImage
                src="../../../images/top/p-job-case--02.jpg"
                alt=""
                loading="lazy"
                quality={80}
              />
            </div>
          </div>
        </div>
        {/* capacity */}
        <div className={Styles.intro}>
          <div className={Styles.introInner}>
            <div className={Styles.bgTextWrap}>
              <p className={Styles.bgText}>CAPACITY</p>
              <h2 className={Styles.bgHeading}>
                <span className={Styles.bgSubHeading}>CAPACITY</span>
                大規模制作も対応できる体制とサービス
              </h2>
            </div>
            <ul className={Styles.capacityList}>
              <li className={Styles.capacityItem}>
                課題発見・戦略・
                <br aria-hidden="true" /> プランニング
              </li>
              <li className={Styles.capacityItem}>
                カート選定
                <br aria-hidden="true" /> サポート
              </li>
              <li className={Styles.capacityItem}>
                ECサイト
                <br aria-hidden="true" /> 構築
              </li>
              <li className={Styles.capacityItem}>
                PDCA運用
                <br aria-hidden="true" /> プロモーション
              </li>
            </ul>
            <p className={Styles.text}>
              EC専属スタッフが多数在籍しており、プロジェクトに合わせて最適な人材を配置することが可能です。
            </p>
          </div>
        </div>
        {/* RATIONALE */}
        <div className={Styles.intro} id="work">
          <div className={Styles.introInner}>
            <div className={Styles.bgTextWrap}>
              <p className={Styles.bgText}>RATIONALE</p>
              <h2 className={Styles.bgHeading}>
                <span className={Styles.bgSubHeading}>RATIONALE</span>
                実績
              </h2>
            </div>
            <p className={Styles.text}>
              大規模サイトのリニューアルや制作運用を長く担当させていただいております。
            </p>
            <div className={Styles.worksWrap}>
              <div className={Styles.workListTop}>
                <p className={Styles.workNumber}>
                  0<span>1</span>
                </p>
                <a
                  href="https://www.bellemaison.jp/"
                  target="_blank"
                  rel="noreferrer"
                  className={Styles.workLink}
                >
                  <figure>
                    <StaticImage
                      src="../../../images/top/p-work--01.jpg"
                      alt="実績紹介01"
                      loading="lazy"
                      quality={80}
                    />
                  </figure>
                </a>
              </div>
              <div className={Styles.workTextarea}>
                <div>
                  <h3 className={Styles.workSubHead}>
                    大規模ECサイトの
                    <br aria-hidden="true" className={Styles.lgdisplay} />
                    {/* <br aria-hidden="true" className={Styles.displayNone} /> */}
                    運用全般をサポート
                  </h3>

                  <p className={Styles.workText}>
                    サイトデザインの改修に、特集ページ・メールマガジン・バナー
                    といったコンテンツの運用や、レスポンシブやリキッドコンテンツテンプレートの導入、そして各種最適化の改善施策や、モバイルアプリの開発から、クライアント先へ常駐しての更新管理まで。パートナープロダクションとして、大規模ECサイトの運用を長期にわたって実施しています。
                  </p>
                  <a
                    href="https://www.tam-tam.co.jp/work/bellemaison/"
                    target="_blank"
                    rel="noreferrer"
                    className={Styles.workMore}
                  >
                    詳細を見る
                  </a>

                  <div>
                    <a
                      href="https://www.bellemaison.jp/"
                      target="_blank"
                      rel="noreferrer"
                      className={Styles.workName}
                    >
                      株式会社 千趣会様 ベルメゾンネット
                    </a>
                    <p className={Styles.workCategory}>総合通販</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.worksWrap}>
              <div className={Styles.workListTop}>
                <p className={Styles.workNumber}>
                  0<span>2</span>
                </p>
                <a
                  href="https://www.bellemaison.jp/"
                  target="_blank"
                  rel="noreferrer"
                  className={Styles.workLink}
                >
                  <figure>
                    <StaticImage
                      src="../../../images/top/p-work--02.jpg"
                      alt="実績紹介02"
                      loading="lazy"
                      quality={80}
                    />
                  </figure>
                </a>
              </div>
              <div className={Styles.workTextarea}>
                <div>
                  <h3 className={Styles.workSubHead}>
                    ECで提供したい
                    <br aria-hidden="true" className={Styles.lgdisplay} />
                    {/* <br aria-hidden="true" className={Styles.displayNone} /> */}
                    体験をカート選定に
                    <br aria-hidden="true" className={Styles.lgdisplay} />
                    落とし込むコンサル
                  </h3>

                  <p className={Styles.workText}>
                    ECサイトの構築には、カートシステムの選定が重要になります。クラウド型かパッケージ型か、どれくらいカスタマイズするか
                    ー
                    選定するアプローチにより、ECサイトが提供できる顧客体験や、その開発コストが大きく変わるからです。
                    メディカ出版様のECで実現したいことの本質を抽出し、それにはどんなカートが求められるのか、EC構築の知見をフル活用して
                    コンサルティング業務をお手伝いしました。
                  </p>
                  <a
                    href="https://www.tam-tam.co.jp/work/medicasyuppan/"
                    target="_blank"
                    rel="noreferrer"
                    className={Styles.workMore}
                  >
                    詳細を見る
                  </a>

                  <div>
                    <p className={Styles.workName + " " + Styles.isNormal}>
                      メディカ出版様
                    </p>
                    <p className={Styles.workCategory}>出版</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.workListsWrap}>
              <div className={Styles.workList}>
                <p className={Styles.workNumber}>
                  0<span>3</span>
                </p>
                <div className={Styles.workImageBlock}>
                  <a
                    href="https://www.daimaru-matsuzakaya.jp/"
                    target="_blank"
                    rel="noreferrer"
                    className={Styles.workLink}
                  >
                    <figure>
                      <StaticImage
                        src="../../../images/top/p-work--03.jpg"
                        alt="実績紹介03"
                        loading="lazy"
                        quality={80}
                      />
                    </figure>
                  </a>
                  <div>
                    <a
                      href="https://www.daimaru-matsuzakaya.jp/"
                      target="_blank"
                      rel="noreferrer"
                      className={Styles.workName}
                    >
                      大丸松坂屋オンラインショップ
                    </a>
                    <p className={Styles.workCategory}>総合通販</p>
                  </div>
                </div>
              </div>

              <div className={Styles.workList}>
                <p className={Styles.workNumber}>
                  0<span>4</span>
                </p>
                <div className={Styles.workImageBlock}>
                  <a
                    href="https://tiger-netshop.jp/shop/default.aspx"
                    target="_blank"
                    rel="noreferrer"
                    className={Styles.workLink}
                  >
                    <figure>
                      <StaticImage
                        src="../../../images/top/p-work--04.jpg"
                        alt="実績紹介04"
                        loading="lazy"
                        quality={80}
                      />
                    </figure>
                  </a>
                  <div>
                    <a
                      href="https://tiger-netshop.jp/shop/default.aspx"
                      target="_blank"
                      rel="noreferrer"
                      className={Styles.workName}
                    >
                      タイガー魔法瓶様 オンラインショップ
                    </a>
                    <p className={Styles.workCategory}>家電</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.workButtonWrap}>
              {/* <div className={`${Styles.buttonWrap} ${Styles.buttonWrapSmall}`}>
                <a
                  href="https://www.tam-tam.co.jp/work/"
                  target="_blank"
                  rel="noreferrer"
                  className={Styles.button}
                >
                  ECチームの実績紹介をもっと知る
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <Form />
      </Layout>
    </>
  )
}

export default PackagePage
